.navbar-spacer {
    width: 100%;
    height: 145px;
    background-color: #000;
}
.navbar {
    background-color: transparent;
    padding: 0px;
    display: block;
    animation: headerNavbarCollapse 0.4s linear;
    .menu-language-changer {
        display: none;
    }
    .hero-content-wrapper {
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        width: 100%;
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;

        hr {
            display: none;
        }
    }

    .nav-title-wrapper {
        width: 38px;
        text-align: right;
        animation: headerTitleSmoothRight 0.4s linear !important;
        .navbar-brand {
            margin-top: 18px;
            margin-bottom: 18px;
            margin-left: 38px;

            display: block;
            img {
                width: 38px;
                height: 49px;
            }
        }

        h2,
        h4 {
            width: 100%;
            display: none;
        }
        .lg-navbar {
            display: none;
        }
    }
    .desktop-lang-change {
        display: none !important;
    }

    .navbar-toggler {
        padding: 0px;
        margin: 0px;
        margin-right: 32px;
        border: none;
        &:focus {
            box-shadow: none;
        }
        img {
            top: 27px;
            right: 21px;
        }
    }

    .hero-image {
        z-index: 20;
        visibility: hidden;
        opacity: 0;
        width: 100%;
        height: 100%;
        animation: headerImageCollapse 0.4s linear;
        box-shadow: inset 0px -700px 200px -200px black;

        background-color: black;
        background-image: url(/images/mobile-hero-small-mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        a {
            display: none;;
        }
    }

    .collapse {
        transform: translateX(100%);
        &.show {
            transform: translateX(0%);
        }
    }

    .collapsing {
        display: block;
        animation: collapseClose 0.6s linear;
        transform: translateX(100%);

        &.show {
            display: block;
            animation: collapseOpen 0.2s linear;
            transform: translateX(0%);
        }
    }

    .collapse,
    .collapsing {
        z-index: 40;
        height: calc(100vh - 94px);
        display: block;
        background-color: #ffffff;
        position: absolute;
        top: 94px;
        left: 0px;
        right: 0px;
        //bottom:0px;

        .nav-item {
            font-family: "Carrois Gothic SC","League Gothic", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            color: #000;
            text-transform: uppercase;
            padding: 0px;
            margin-bottom: 42px;

            &:focus,
            &.active {
                color: #fff;
                background-color: #000;
            }

            &:first-child {
                margin-top: 98px;
            }

            &.language {
                margin-top: 44px;
                font-family: "Changa";
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 46px;
                text-transform: capitalize;
                img {
                    width: 24px;
                    height: 24px;
                }
                a {
                    display: inline;
                }
            }

            .nav-link {
                color: #000 !important;
                padding: 0px;
                margin: 0px;
            }
        }
    }

    &.expanded {
        height: 100vh;
        animation: none !important;

        .hero-content-wrapper {
            padding-bottom: 24px;
        }
        .nav-title-wrapper {
            animation: fadeUp 1.1s linear !important;
            width: 100%;
            text-align: center;
            .navbar-brand {
                display: block;
                margin: 0px;
                padding: 0p;
                margin-top: 43px;
                img {
                    width: 62px;
                    height: 80px;
                }
            }
            h2 {
                margin: 0px;
                padding: 0px;
                margin-top: 18px;
                display: inline-block;
            }
            h4 {
                margin: 0px;
                padding: 0px;
                margin-top: 9px;
                display: inline-block;
            }
        }
        .navbar-toggler {
            position: absolute;
            top: 32px;
            right: 32px;
            padding: 0px;
            margin: 0px;
            img {
                top: 27px;
                right: 21px;
            }
        }
        .hero-image {
            visibility: visible;
            animation: headerImageExpand 0.4s linear !important;
            height: 100%;
            opacity: 1;
            a {
                display: block;
                width: 37px;
                height: 37px;

                position: absolute;
                bottom: 30px;
                left: calc(50% - (37px / 2));
                img {
                    margin: 0px;
                    padding: 0px;
                    width: 37px;
                    height: 37px;
                }
            }
        }
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes headerTitleSmoothRight {
    0% {
        width: calc(50% + (38px / 2));
    }
    100% {
        width: 38px;
    }
}

@keyframes collapseOpen {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes collapseClose {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes headerImageExpand {
    0% {
        visibility: visible;
        height: 0%;
        opacity: 0;
    }
    100% {
        visibility: visible;
        height: 100%;
        opacity: 1;
    }
}

@keyframes headerImageCollapse {
    0% {
        background-color: purple;
        opacity: 1;
        visibility: visible;
    }
    99% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        background-color: greenyellow;
        height: 0%;
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes headerNavbarCollapse {
    0% {
        height: 100%;
    }
    99% {
        height: 95%;
    }
    100% {
        height: auto;
    }
}

@keyframes desktopNavbarBrandCollapse {
    0% {
        left: calc(50% - 116px / 2);
    }

    100% {
        height: 69px;
    }
}

@keyframes desktopLgNavbar {
    0% {
        margin-top: 336px;
    }

    100% {
        margin-top: 32px;
    }
}

@keyframes desktopLangChange {
    0% {
        top: 68px;
    }

    100% {
        top: 18px;
    }
}

@keyframes hrAnimation {
    0% {
        width: 0px;
    }

    100% {
        width: 916px;
    }
}

@include media-breakpoint-up(xl) {
    body {
        overflow: hidden;
    }

    .navbar {
        .navbar-collapse {
            display: none !important;
        }

        .hero-content-wrapper {
            padding: 0px !important;

            .nav-title-wrapper {
                width: 100%;
                text-align: right;
                animation: fadeUp 1.1s linear !important;
                .navbar-brand {
                    animation: desktopNavbarBrandCollapse 0.4s linear;

                    position: absolute !important;
                    top: 6px;
                    left: 95px;

                    margin: 0px !important;
                    padding: 0px !important;

                    .header-logo {
                        width: 54px !important;
                        height: 69px !important;
                    }
                }
                h2 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 48px;
                    margin-top: 162px !important;
                    text-align: center;
                }

                h4 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 24px;
                    margin-top: 16px !important;
                    text-align: center;
                }

                .lg-navbar {
                    animation: desktopLgNavbar 0.4s linear;
                    display: block;
                    width: 1000px;
                    margin: auto;
                    margin-top: 28px;
                    text-align: center;

                    .nav-item {
                        font-family: "Carrois Gothic SC","League Gothic", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 24px;
                        text-align: center;
                        margin: 0px;
                        padding: 0px;

                        padding-bottom: 28px;
                        color: #000;
                        width: 160px;
                        margin-left: 45px;
                        margin-right: 45px;

                        &:hover,
                        &.active {
                            border-bottom: solid 13px #000;
                            padding-bottom: 15px;
                        }

                        .nav-link {
                            color: #000;
                            padding: 0px;
                            margin: 0px;
                        }
                    }
                }
            }
            .desktop-lang-change {
                display: block !important;
                animation: desktopLangChange 0.4s linear;
                text-align: right;
                position: absolute;
                top: 18px;
                right: 94px;
                width: 100px;

                font-family: "Changa";
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 46px;
                color: #000;
                text-transform: capitalize;
                a {
                    font-family: "Changa";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 46px;
                    color: #000;
                    text-decoration: none;
                    text-transform: capitalize;
                }
            }
        }
        &.expanded {
            .hero-content-wrapper {
                padding: 0px !important;
                .nav-title-wrapper {
                    .navbar-brand {
                        animation: none;
                        top: 38px;
                        left: calc(50% - 116px / 2);

                        margin: 0px !important;
                        padding: 0px !important;

                        .header-logo {
                            width: 93px !important;
                            height: 116px !important;
                        }
                    }

                    hr {
                        animation-delay: 1.2;
                        animation: hrAnimation 1.2s linear;
                        display: block;
                        margin: auto;
                        width: 916px;
                        margin-top: 24px;
                        color: #000;
                        opacity: 1;
                    }
                    .lg-navbar {
                        animation: none !important;
                        margin-top: 22px;
                    }
                }
                .desktop-lang-change {
                    display: block !important;
                    animation: none !important;
                    top: 68px;
                }
            }

            .hero-image {
                box-shadow: inset 0px -700px 200px -200px black;
                animation: headerImageCollapse 0.4s linear;
                background-image: url(/images/hero-bg-crop.webp);
                background-position: 0px -200px;
            }
        }
    }
}


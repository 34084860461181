@import "variables";

@import "bootstrap/scss/bootstrap";

@import "./hero.scss";
@import "./language_change_anim.scss";
@import "./sliders.scss";

@import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");

@font-face {
    font-family: "Changa";
    src: url("../fonts/Changa-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Keep Calm Medium";
    src: url("../fonts/KeepCalm-Medium.ttf");
}

body {
    font-family: "Carrois Gothic SC", sans-serif;
    text-transform: lowercase;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

input[type="button"]:focus {
    outline: transparent;
}

.film-grain {
    background-size: cover;
    z-index: 2000;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.1;
    //background-image: url(/images/film-grain.gif);
    pointer-events: none;
}

@mixin fadein-anim {
    -webkit-animation: fadein 1.5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.5s;
    /* Firefox < 16 */
    -ms-animation: fadein 1.5s;
    /* Internet Explorer */
    -o-animation: fadein 1.5s;
    /* Opera < 12.1 */
    animation: fadein 1.5s;
}

@mixin flex-end {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

/**********    intro section   ************/

.intro-section {
    display: flex;
    justify-content: center;
    background-color: #000;
    padding: 5% 0 10% 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    .intro-container,
    .intro-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5em 0 1em 0;

        .intro-subtitle {
            margin-top: 0.6em;
            font-size: 1.3em;
        }

        .intro-text {
            font-size: 0.9em;
            margin-top: 0.5em;
        }

        ol {
            padding-inline-start: 1.4em;
            line-height: 1.8;

            li {
                font-size: 0.9em;
            }
        }
    }

    .list-container {
        border-left: 1px solid #fff;
        padding-left: 1em;

        .list-title {
            font-size: 1.3em;
            margin-bottom: 0.8em;
        }
    }

    .contact-btn {
        margin: 1em 0;
        background-color: #000;
        border: 2px solid #fff;
        color: #fff;
        padding: 4px 30px;
        font-size: 1.3em;
        border-radius: 4px;
        transition: 0.5s;

        &:hover {
            background-color: #fff;
            border: 2px solid #fff;
            color: #000;
            cursor: pointer;
        }
    }
}

/************   video slider section  ***********/

/*

*/

/***********  photo slider section ***********/

/*
.photo-slider-section {
    padding: 10vh 0;
    min-height: 140vh;
    background-color: #1C1C1C;

    #photo-slider-wrapper {
        height: 100%;

        .main-row {
            position: relative;

            .carousel-control-prev,
            .carousel-control-next {
                position: absolute;
                top: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                color: #fff;
                height: 2.5em;
                width: 2.5em;
                background-color: #000 !important;
                border-radius: 50%;
                text-align: center;
                background: none;
                border: 0;
                opacity: 0.5;
                transition: opacity 0.15s ease;

                span {
                    width: 20px;
                    height: 20px;
                }
            }

            .carousel-control-prev {
                left: 15%;
            }

            .carousel-control-next {
                right: 15%;
            }

            .photo-slider-container {
                width: 50%;
                max-height: 120vh;
                background-color: #000;
                padding: 3%;
                margin: auto;

                .carousel-inner {
                    max-height: 100%;

                    .img-preview-container {
                        .movie-lines {
                            display: none;
                        }

                        .image-wrapper {
                            position: relative;

                            .col {

                                .row {
                                    gap: 1.5em;

                                    .col-12 {

                                        figure {
                                            margin: 0;

                                            .img-fluid {
                                                width: 100%;
                                                max-height: 30%;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                    .preview-container {
                        position: relative;
                        max-height: 100vh;

                        .img-fluid {
                            width: auto;
                            height: 100%;
                            object-fit: contain;
                            padding-top: calc(var(--bs-gutter-x) * 0.5);
                        }

                        span {
                            position: absolute;
                            top: 3%;
                            right: 2%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}*/

/***********     contact section   **********/

.contact-section {
    height: 78vh;
    padding: 45px 0px;
    background-color: #000;
    display: flex;
    justify-content: center;

    form {
        #website {
            display: none;
        }

        /* Style inputs with type="text", select elements and textareas */
        input,
        select,
        textarea,
        button {
            background-color: #000;
            border-color: #000;
            color: #fff;
            border: solid 0px black;
            border-radius: 0px !important;
            border-bottom: 1px solid #fff;
            outline: none;
            width: 100%;
            /* Full width */
            padding: 8px 0;
            /* Gray border */
            border-radius: 4px;
            /* Rounded borders */
            box-sizing: border-box;
            /* Make sure that padding and width stays in place */
            margin-top: 6px;
            /* Add a top margin */
            margin-bottom: 16px;
            /* Bottom margin */
            resize: vertical;
            /* Allow the user to vertically resize the textarea (not horizontally) */
        }

        #name {
            margin-right: 3em;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #cccccc;
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #cccccc;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #cccccc;
        }

        /* Style the submit button with a specific background color etc */
        button {
            color: white;
            border: none;
            cursor: pointer;
        }
    }
}

/********   footer  *********/

#footer {
    background-color: #000;

    .footer-container {
        background-color: #535353;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        min-height: 15vh;
        transition: 1s;
        opacity: 1;

        .footer-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;

            a {
                text-decoration: none;
                color: #fff;
            }
        }

        .footer-first-col {
            font-size: 0.8em;
        }

        .footer-second-col {
            p {
                margin-bottom: 0.5rem;

                &:first-child {
                    font-size: 1.3em;
                }

                &:last-child {
                    font-size: 0.9em;
                }
            }
        }

        .footer-third-col {
            padding-right: 8%;
            flex-direction: row;
            justify-content: flex-end;

            img {
                &:last-child {
                    margin-left: 1.5em;
                }
            }
        }
    }
}

/*******    animations    ********/

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/**********   JS classes - classList.add     **********/
/*
.active {
    border-bottom: 10px solid #000;
}
*/

.hide {
    display: none !important;
}

.block {
    display: block !important;
    margin-top: 2em;
}

.fade-out {
    opacity: 0;
}
/*
.nav-font-max {
    transition: 1s;
    font-size: 20px !important;
}
*/
.no-transition {
    transition: none;
}
/*
.hero-cont-height {
    height: 65vh !important;
}

.hero-bg-height {
    height: 100% !important;
}

.nav-container-scroll {
    margin-top: -30vh;
    padding: 0 !important;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.nav-lower-grid {
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav-upper-hide {
    margin-top: -20% !important;
}

.header-logo-resize {
    width: 50%;
}

.header-logo-div-pos {
    position: absolute;
    left: 5%;
    bottom: 2.5%;
}

.logo-scroll {
    max-width: 35px !important;
    margin-left: 10%;
}

.enlarge-img {
    transform: scale(2);
    object-fit: contain;
}*/

.footer-fade-in {
    opacity: 1 !important;
}

/**********    thank-you.html    **********/

#thank-you-body {
    background-color: #000;

    .thank-you-wrapper {
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: auto !important;
        color: #fff;
        font-size: 1.5em;
        line-height: 2.2;
    }
}

/*********    media queries   **************/

@media screen and (max-width: 1200px) {
    .film-grain {
        display:none;
    }
    #intro-section {
        .intro-wrapper {
            max-width: 70%;
            text-align: center;
        }
    }

    .contact-container {
        form {
            padding: 0 15%;

            .d-flex {
                display: block !important;
            }
        }
    }

    #footer {
        .footer-container {
            opacity: 1;
            display: flex;
            flex-direction: column;
            gap: 1em;
            justify-content: center;
            padding: 2em 0;
            align-items: center;

            .footer-first-col {
                order: 2;
            }

            .footer-second-col {
                order: 0;
            }

            .footer-third-col {
                order: 1;
            }
        }
    }
}

//Preview For images

.preview {
    cursor: pointer;

    .image {
    }

    .video {
    }
}

.photo-slider-section,
.video-slider-section {
    figure {
        margin: 0px;
        padding: 0px;
    }
    height: 100vh;
    //padding-top: 80px;
    background-color: #1c1c1c;
    display: flex;

    .carousel-control-prev,
    .carousel-control-next {
        width: 50px;
        height: 50px;
        top: calc(50% - 60px);
        border-radius: 50px;
        bottom: initial;
        background-color: black;
    }
    .carousel-control-next {
        right: -50px;
        .carousel-control-next-icon {
            background-image: url("/images/arrow-next-vector.svg");
        }
    }
    .carousel-control-prev {
        left: -50px;
        .carousel-control-prev-icon {
            background-image: url("/images/arrow-prev-vector.svg");
        }
    }

    .photo-slider-wrapper,
    .video-slider-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 270px;

        justify-content: center;
        background-color: black;
        margin: auto;

        .video-roll-layer {
            background-image: url("/images/video-roll.svg");
            background-repeat: repeat-x;
            height: 43px;
            margin-bottom: 40px;
        }

        .preview-wrapper {
            z-index: 5;

            background-color: rgba(0, 0, 0, 1);

            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            animation: previewAnimation 0.8s linear;
            .preview-close {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 7;
                top: 0px;
                right: 0px;
                font-size: 40px;
                border-radius: 68px;
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 48px;
                font-weight: bold;
                cursor: pointer;
                color: #fff;
            }

            .media-preview {
                margin-left: 10px;
                margin-right: 10px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                video,
                figure {
                    max-width: 90%;
                    max-height: 90%;
                    display: block;
                    margin: auto;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto !important;
                        display: block;
                        margin: auto;
                    }
                }
                p {
                    position: absolute;
                    bottom: 0px;
                    margin: 0px;
                    padding: 0px;

                    width: 90%;
                    font-family: "League Gothic", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 30px;
                    text-transform: capitalize;

                    color: #ffffff;
                    animation: textFadeUpHover 0.5s linear;
                }
            }
        }

        .main-row {
            position: relative;

            max-height: 86vh;
            overflow-y: clip;
            width: 100%;
            margin: 0px;

            video {
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 10px;
            }
            .youtube-embed{
                width: 100%;
            }
            .row,
            .col,
            .col-4,
            .col-6,
            .col-12 {
                padding: 0px;
                margin: 0px;
            }
            .media-row {
                margin-left: 2.5px;
                margin-right: 2.5px;
                .media-wrapper {
                    margin-bottom: 5px;
                    position: relative;
                    text-align: center;
                    &:hover {
                        .hover-overlay {
                            animation: overlayFadeUpHover 0.3s linear;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            p {
                                animation: textFadeUpHover 0.5s linear;
                            }
                        }
                    }

                    .hover-overlay {
                        pointer-events: none;
                        display: none;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        bottom: 0px;
                        right: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        p {
                            pointer-events: none;
                            font-family: "League Gothic", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;
                            text-align: center;
                            text-transform: capitalize;

                            color: #ffffff;
                        }
                    }
                }
            }

            .image-wrapper {
                opacity: 1;
                &.opened {
                    opacity: 0;
                }
                .image {
                    margin: 0px;
                    padding: 0px;
                    cursor: pointer;
                    /*margin-top: 10px;
                    margin-left: 5px;
                    margin-right: 5px;*/
                    //width: 100%;
                    display: block;
                    margin: auto;
                    img {
                        display: block;
                        margin: auto;
                        //width: 100%;
                    }
                }
            }
        }
    }
}

@keyframes previewAnimation {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, 1);
    }
}

@keyframes textFadeUpHover {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes overlayFadeUpHover {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include media-breakpoint-up(xl) {
    .photo-slider-section,
    .video-slider-section {
        figure {
        }

        .carousel-control-next {
            right: -102px;
        }
        .carousel-control-prev {
            left: -102px;
        }

        .photo-slider-wrapper,
        .video-slider-wrapper {
            width: 900px;
            max-width: 900px;
        }
    }
}


.hide-by-lang{
    display: none !important;
}

.text-fade-out{
    animation: fadeOut 1.1s linear !important;
    opacity: 1;
}


@keyframes fadeOut {
    0% { opacity: 1 }

    80% { opacity: 0 }
    100% { opacity: 0 }
}


.text-fade-in{
    opacity: 0 ;

    animation: fadeUp 0.3s ease-in !important;
}


@keyframes fadeUp {
    0% { 
        opacity: 0 ;
        transform: translateY(10px);
    }
    80% { 
        opacity: 1 ;
        transform: translateY(0px);
    }

    100% { 
        
        opacity: 1;
        transform: translateY(0px);
    }

}